<template>
  <div id="app">
    <div class="images">
      <!-- 拡大プレビューエリア -->
      <div class="slides-container" :style="slidesContainerStyle">
        <!--表示するのかどうかを決め、クリックされたら親に比率を伝える-->
        <div
          class="cell"
          @mouseover="showZoomArea = true"
          @mouseleave="showZoomArea = false"
          @click="shareRatio"
        >
          <div class="m-lens-container" @click="changePreviewArea">
            <!--基準となる画像。常に表示-->
            <img :src="url" :style="mLensContainerImgStyle" />
            <!--拡大エリアの位置を指定-->
            <div class="m-lens" :style="lensStyle"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Diagram",
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    //全体のバランスのためにこの値は固定にしておく。
    imgWidth: 700 / 1.1,
    imgHeight: 500 / 1.1,
    size: null,
    scale: null,
    //常にマウスの座標の中での位置を追跡。クリックされたときに親コンポーネントに共有
    ratio: null,
    //拡大エリアを表示するかどうか
    showZoomArea: false,
    slidesContainerStyle: {
      width: null,
    },
    lensStyle: {
      top: null,
      left: null,
      height: null,
      width: null,
    },
    zoomAreaStyle: {
      top: null,
      left: null,
    },
    zoomImageStyle: {
      width: null,
      marginLeft: null,
      marginTop: null,
    },
    mLensContainerImgStyle: {
      maxHeight: null,
      maxWidth: null,
    },
  }),
  methods: {
    // 拡大プレビューの変更
    changePreviewArea(event) {
      let size = this.size;
      let scale = this.scale;

      // 座標を取得
      let container = document.querySelectorAll(".m-lens-container");
      let rect = container[0].getBoundingClientRect();
      let mouseX = event.pageX;
      let mouseY = event.pageY;

      // スクロール分も計算に入れる
      // コンテナの左上からの距離 = ページの左上からの距離 - スクロール量 - ウィンドウの左上からの距離
      let positionX = rect.left + window.pageXOffset;
      let positionY = rect.top + window.pageYOffset;
      let offsetX = mouseX - positionX;
      let offsetY = mouseY - positionY;
      //拡大エリアの左端と上端の座標を代入。
      let left = offsetX - size / 2;
      let top = offsetY - size / 2;

      // 拡大エリアの限界値を設定
      let img = container[0].querySelector("img");
      let xMax = img.offsetWidth - size / 2;
      let yMax = img.offsetHeight - size / 2;
      if (left > xMax) {
        left = xMax;
      }
      if (top > yMax) {
        top = yMax;
      }
      if (left < 0) {
        left = 0;
      }
      if (top < 0) {
        top = 0;
      }

      // 拡大エリアの位置
      this.lensStyle.top = "0px";
      this.lensStyle.left = left + "px";

      // 拡大エリアのプレビュー表示箇所の変更処理
      this.zoomImageStyle.marginLeft = -(left * scale) + "px";
      this.zoomImageStyle.marginTop = -(top * scale) + "px";

      //時刻の大きさをここで更新。画像の大きさは固定なのでこれは弄らなくて良いはず
      this.ratio = left / xMax;
      var start = 0.022;
      var end = 0.91;
      if (this.ratio < start) {
        this.ratio = 0;
      } else if (this.ratio > end) {
        this.ratio = 1;
      } else {
        this.ratio = (this.ratio - start) / (end - start);
      }
    },
    // styleのプロパティをセット
    setStyleProperty() {
      let width = this.imgWidth;
      let height = this.imgHeight;

      // コンテナのサイズを設定
      this.slidesContainerStyle.width = width;

      // 表示する基準の画像のサイズを設定
      this.mLensContainerImgStyle.maxHeight = height + "px";
      this.mLensContainerImgStyle.maxWidth = width + "px";

      // ズームエリアの位置を設定
      this.zoomAreaStyle.left = width - 0 + "px";

      // 拡大鏡エリアのサイズを設定
      // 数値を変更することでズームエリアの画像サイズも変更される
      this.size = width >= height ? width * 0.01 : height * 0.06;
      let size = this.size;
      this.lensStyle.height = size * 100 + "px";
      this.lensStyle.width = size + "px";

      // スケールの設定
      this.scale = 100 / size;
      let scale = this.scale;

      // ズームエリアに表示する画像のサイズを設定
      this.zoomImageStyle.width = width * scale + "px";
    },
    shareRatio() {
      //親コンポーネントへ比率を送信
      this.$emit("update", this.ratio);
    },
  },
  mounted() {
    //ビュー全体がレンダリングされた後に実行される。
    this.setStyleProperty();
  },
};
</script>

<style scoped>
.m-lens-container {
  display: inline-block;
  position: relative;
}
.m-lens {
  position: absolute;
  z-index: 2;
  background: blue;
  opacity: 0.5;
}
.cell {
  display: table-cell;
}
.images {
  position: relative;
}
.slides-container {
  overflow: hidden;
}
.zoom-area {
  display: none;
  position: absolute;
  border: 5px solid #ccc;
  height: 400px;
  width: 60px;
  overflow: hidden;
}
.zoom-area.active {
  display: block;
}
</style>
