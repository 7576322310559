<template>
  <div>
    <h2>FaceCalibration</h2>
    <v-card>
      <v-simple-table fixed-header height="600px">
        <thead>
          <tr>
            <th>id</th>
            <th>image</th>
            <th v-for="j in members" :key="j">
              <v-text-field label="Name" v-model="names[j - 1]"></v-text-field>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(face, i) in faces" :key="i" class="column">
            <td>{{ i + 1 }}</td>
            <td>
              <div class="container-item-img">
                <img :src="face_url[i]" />
              </div>
            </td>
            <td v-for="j in members" :key="j">
              <v-radio-group v-model="faces[i]">
                <v-radio :value="j"></v-radio>
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>
    <div class="my-5" />
    <v-btn @click="save">
      save
    </v-btn>
  </div>
</template>

<script>
import { Storage } from "aws-amplify";
import csv from "csv";

export default {
  name: "FaceCalibration",
  data: () => ({
    faces: [],
    face_url: [],
    names: [],
    members: 0,
    prev_records: null,
  }),
  props: {
    url: {
      type: String,
      required: true,
    },
    mtg_id: {
      type: String,
      required: true,
    },
    n_face: {
      type: Number,
      required: true,
    },
  },
  methods: {
    getUrl: async function(key, handler) {
      const dataExpireSeconds = 60 * 60; // 1時間有効なURLを取得
      Storage.get("mtgs/" + this.mtg_id + "/" + key, {
        level: "private",
        expires: dataExpireSeconds,
      })
        .then((result) => {
          handler(result);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    save: function() {
      const records = this.prev_records.concat();
      records.forEach((col, i) => {
        //const name = col.Name;
        col.Name = this.names[i];
        col.HCIndex = this.faces
          .reduce((a, v, j) => (v == i + 1 ? [...a, j] : a), [])
          .join(",");
      });
      csv.stringify(
        records,
        {
          header: true,
        },
        (err, out) => {
          if (err) {
            console.error(err);
          } else {
            this.$emit("update", out);
          }
        }
      );
    },
  },
  watch: {
    url: function(value) {
      this.axios.get(value).then((result) => {
        console.log(result.data);
        csv.parse(result.data, { columns: true }, (error, records, info) => {
          if (error) {
            console.error(error);
            return;
          }
          console.log(records);
          console.log(info);
          this.prev_records = records;
          this.faces = [];
          this.members = records.length;
          this.names = Array(this.members);
          records.forEach((col, i) => {
            const name = col.Name;
            this.names[i] = name;
            const indexs = col.HCIndex.split(",");
            indexs.forEach((index) => {
              this.faces[index] = Number(col.FixIndex);
            });
          });
        });
        this.face_url = Array(this.n_face);
        for (let i = 0; i < this.n_face; ++i) {
          this.getUrl(`FaceCalibration/HCIndex/${i + 1}.jpg`, (result) => {
            console.log(i);
            console.log(result);
            this.face_url.splice(i, 1, result);
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.column {
}
th,
td {
  width: 300px;
}

.container-item-img img {
  display: block;
  height: 100px;
  width: 100px;
  object-fit: cover;
  width: 100%;
}
</style>
