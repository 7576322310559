<template>
  <div class="uploader pa-2">
    <v-row class="">
      <v-col v-for="(file, i) in files" :key="i">
        <v-card outlined class="pa-2" v-if="!(file.name == 'audio_folder')">
          <v-file-input
            :prepend-icon="file.icon"
            @change="selected(i, $event)"
            :accept="file.mimeType"
            :name="file.name"
            show-size
            truncate-length="20"
            :label="file.name"
          ></v-file-input>
          <v-btn @click="upload(i)">upload</v-btn>
          <div>
            {{ file.msg }}
          </div>
        </v-card>
        <v-card outlined class="pa-2" v-if="file.name == 'audio_folder'">
          <v-file-input
            :prepend-icon="file.icon"
            @change="selected(i, $event)"
            :accept="file.mimeType"
            :name="file.name"
            show-size
            truncate-length="20"
            :label="file.name"
            webkitdirectory
            multiple
            counter
          ></v-file-input>
          <v-btn @click="upload_folder(i)">upload</v-btn>
          <div>
            {{ file.msg }}
          </div>
        </v-card>
      </v-col>
    </v-row>
    <amplify-authenticator />
  </div>
</template>

<script>
import { Storage } from "aws-amplify";

export default {
  name: "Upload",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fileMov: null,
      fileDiag: null,
      fileTranscript: null,
      files: [
        {
          content: null,
          name: "mov.mp4",
          icon: "mdi-camera",
          mimeType: "video/mp4",
          msg: "",
        },
        {
          content: null,
          name: "diagram.png",
          icon: "mdi-chart-bar",
          mimeType: "image/png",
          msg: "",
        },
        {
          content: null,
          name: "transcript.csv",
          icon: "mdi-message-text-outline",
          mimeType: "text/csv",
          msg: "",
        },
        {
          content: null,
          name: "apisnote.json",
          icon: "mdi-file-tree-outline",
          mimeType: "application/json",
          msg: "",
        },
        {
          content: null,
          name: "audio_only.m4a",
          icon: "mdi-music-note",
          mimeType: "audio/x-m4a",
          msg: "",
        },
        {
          content: null,
          name: "audio_folder",
          icon: "mdi-folder-music-outline",
          mimeType: "audio/x-m4a",
          msg: "",
        },
      ],
    };
  },
  methods: {
    selected: function(i, e) {
      this.files[i].content = e;
      this.files[i].msg = "";
      console.log(e);
    },
    upload: function(i) {
      const { name, content, mimeType } = this.files[i];
      if (!content) {
        this.files[i].msg = "*please select file*";
        return;
      }
      const uploadName = `mtgs/${this.id}/${name}`;
      Storage.put(uploadName, content, {
        level: "private",
        contentType: mimeType,
        progressCallback: (progress) => {
          var msg = progress.loaded + " / " + progress.total;
          console.log(msg);
          if (progress.loaded == progress.total) {
            msg = "upload complete.";
          } else if (parseInt(progress.loaded) && parseInt(progress.total)) {
            msg =
              "uploading... " +
              Math.floor(
                (100.0 * parseInt(progress.loaded)) / parseInt(progress.total)
              ) +
              " %";
          }
          this.files[i].msg = msg;
        },
      })
        .then(function(response) {
          // response 処理
          console.log(response);
        })
        .catch(function(error) {
          // error 処理
          console.log(error);
        });
    },
    upload_folder: function(i) {
      const { content, mimeType } = this.files[i];
      if (!content) {
        this.files[i].msg = "*please select file*";
        return;
      }
      const uploadFolder = `mtgs/${this.id}/AudioRecord/`;
      for (let j = 0; j < content.length; j++) {
        if (content[j].type == mimeType) {
          Storage.put(uploadFolder + content[j].name, content[j], {
            level: "private",
            contentType: mimeType,
            progressCallback: (progress) => {
              var msg = progress.loaded + " / " + progress.total;
              console.log(msg);
              if (progress.loaded == progress.total) {
                msg = "upload complete.";
              } else if (
                parseInt(progress.loaded) &&
                parseInt(progress.total)
              ) {
                msg =
                  "uploading... " +
                  Math.floor(
                    (100.0 * parseInt(progress.loaded)) /
                      parseInt(progress.total)
                  ) +
                  " %";
              }
              this.files[i].msg = msg;
            },
          })
            .then(function(response) {
              // response 処理
              console.log(response);
            })
            .catch(function(error) {
              // error 処理
              console.log(error);
            });
        }
      }
    },
  },
};
</script>
