<template>
  <!--トランスクリプトの表示。scssのtranscriptを使用。-->
  <div>
    <v-row>
      <v-col>
        <v-btn @click="whoShow(-1)">
          {{ "全員表示" }}
        </v-btn>
      </v-col>
      <div v-for="(item, index) in color_json" :key="item.number">
        <v-col>
          <v-btn
            @click="whoShow(index)"
            small
            v-bind:color="buttonManager(index)"
          >
            {{ item.name }}
          </v-btn>
        </v-col>
      </div>
    </v-row>
    <v-row>
      <v-col> 時刻 </v-col>
      <v-col> 人物名 </v-col>
      <v-col></v-col>
      <v-col> 発話内容 </v-col>
      <v-col></v-col>
      <v-col></v-col>
    </v-row>
    <div id="transcript" class="overflow-y-auto">
      <div v-for="(line, index) in transcript" :key="line[0]" id="a">
        <v-row v-if="showOrNot(index)">
          <v-col cols="1" v-bind:id="index">
            <v-card v-bind:color="changeColor(index)">
              {{ line[1] }}
            </v-card>
          </v-col>
          <v-col cols="2" class="name">
            <v-card v-bind:color="changeColor(index)">
              {{ line[2] }}
            </v-card>
          </v-col>
          <v-col>
            <v-card v-bind:color="changeColor(index)">
              {{ line[3] }}
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"; //axiosはhttp通信を簡単に行うことができるjsのライブラリ

export default {
  name: "Transcript",
  props: {
    url: {
      type: String,
      required: true,
    },
    giveTime: {
      type: Date,
    },
  },
  data: () => ({
    transcript: [],
    max_trans_items: 0,
    range: 1,
    who: 0,
    whoShowText: "全員表示",
    howMinuteText: "1分表示",
    showAll: true,
    showAllOrNotText: "全表示",
    color_json: [
      {
        number: 1,
        color: "red lighten-4",
        name: "1",
      },
      {
        number: 2,
        color: "blue lighten-4",
        name: "2",
      },
      {
        number: 3,
        color: "yellow lighten-4",
        name: "3",
      },
      {
        number: 4,
        color: "orange lighten-4",
        name: "4",
      },
      {
        number: 5,
        color: "pink lighten-4",
        name: "5",
      },
      {
        number: 6,
        color: "purple lighten-4",
        name: "6",
      },
    ],
  }),
  methods: {
    processTranscript: function(data) {
      //awsからのjsonファイルの読み込み
      var lines = data.split("\n");
      this.transcript = [];
      for (var i = 0; i < lines.length; ++i) {
        this.transcript[i] = lines[i].split(",");
        if (i > 0) this.transcript[i][1] = this.transcript[i][1].split(" ")[1];
      }
      //ここで全体の長さを取得して代入する
      this.max_trans_items = Object.keys(this.transcript).length;
      //jsonファイルの読み込み終了後に名前を格納(awsからとって来ているならここで処理)
    },
    whoShow: function(index) {
      //この関数はボタンから誰を表示するのかを決める。
      if (index == -1) {
        //全員表示ボタンが押された時
        this.who = 0;
      } else {
        this.who = index + 1;
      }
    },
    buttonManager: function(index) {
      //この関数はボタンの色を変化させる
      return this.color_json[index].color;
    },
    changeColor: function(index) {
      //この関数は人によって色を変化させる。
      //もともと5人分の色のjsonを用意しておく。
      //1つ目には番号、2つ目には色、三つ目には人の名前を格納していく。
      var name = this.transcript[index][2];
      for (let i = 0; i < 6; i++) {
        if (this.color_json[i].name == name) {
          return this.color_json[i].color;
        }
      }
    },
    timeToDate: function(transcript_time) {
      //この関数はdiagramの時刻をDateオブジェクトに変更する
      //transcriptでは時刻を"19:07:32"のように表示している
      transcript_time = new String(transcript_time);
      var transcript_date = new Date();
      transcript_date.setFullYear(this.giveTime.getFullYear());
      transcript_date.setMonth(this.giveTime.getMonth());
      transcript_date.setDate(this.giveTime.getDate());
      var hour = Number(transcript_time.substr(0, 2));
      var minute = Number(transcript_time.substr(3, 2));
      var second = Number(transcript_time.substr(6, 2));
      transcript_date.setHours(hour);
      transcript_date.setMinutes(minute);
      transcript_date.setSeconds(second);
      //console.log(transcript_date, this.giveTime);
      return transcript_date;
    },
    rangeDecideShowOrNot: function(DateTranscript) {
      //この関数は+/- range(分)以内の会話ならTrueを、そうでなければFalseを返す。
      var DateTime = this.giveTime.getTime();
      var TranscriptTime = DateTranscript.getTime();
      var time = TranscriptTime - DateTime;
      //console.log(time);
      if (time < (1000 * this.range * 60) / 2) {
        if (time > (-1000 * this.range * 60) / 2) {
          return true;
        }
      }
      return false;
    },
    showOrNot: function(index) {
      //この関数は表示する時に必ず呼ばれるのでここで時刻の処理もする
      if (this.who == 0) {
        return true;
      } else {
        if (this.color_json[this.who - 1].name == this.transcript[index][2]) {
          return true;
        }
        if (this.color_json[this.who - 1].name != this.transcript[index][2]) {
          return false;
        }
      }
    },
    scrollManager: function() {
      //欲しいid取得
      var index = 0;
      var temp_index = 0;
      for (let i = 0; i < this.transcript.length; i++) {
        //console.log(this.transcript[i]);
        var element_time = this.transcript[i][1];
        var element_date = this.timeToDate(element_time);
        var give_time_date_time = this.giveTime.getTime();
        var element_date_time = element_date.getTime();
        var time = element_date_time - give_time_date_time;
        if (time > 0) {
          //指定した時間を越えたらそのインデックスを返すが、今誰を表示しているのかも確認
          if (this.who == 0) {
            index = i;
            break;
          } else {
            //この時には指定した人物の発話の中でのみ考える。
            var name = this.color_json[this.who - 1].name;
            var index_name = this.transcript[i][2];
            if (name == index_name) {
              index = i;
              break;
            }
          }
        } else {
          //ともかくも指定した話者の発言は一箇所はあるはずで、見つからなかったらそれを使う。
          if (name == index_name) {
            temp_index = i;
          }
        }
        //もし最後まで探索しても見つからなかった時の処理
        if (i == this.transcript.length - 1) {
          if (this.who == 0) {
            i = this.transcript.length - 1;
          } else {
            index = temp_index;
          }
        }
      }
      var element = document.getElementById(String(index));
      //要素の位置まで画面をスクロール
      element.scrollIntoView({ behavior: "smooth", block: "end" });
    },
  },
  watch: {
    url: function(value) {
      if (!value) return;
      axios.get(value).then((result) => {
        this.processTranscript(result.data);
      });
    },
    giveTime: function() {
      this.scrollManager();
    },
  },
};
</script>

<style lang="scss">
#transcript {
  margin: 5px;
  max-height: 350px; //apsinoteの大きさに合わせるために変更
  border: solid black 1px;
  .name {
    font-size: 80%;
  }
}
</style>
