<template>
  <!--このidが下のscssで指定したもの。videoを表示。オートプレイはしない。-->
  <div id="mov">
    <video id="movie" controls :src="url"></video>
  </div>
</template>

<script>
export default {
  name: "Movie",
  props: {
    url: {
      type: String,
      required: true,
    },
    giveTime: {
      type: Number,
    },
  },
  watch: {
    giveTime: function() {
      //変更があったときにのみ再生位置を変更する
      this.syncPosition();
    },
  },
  methods: {
    time_update: function(e) {
      this.$emit("time_update", e.target.currentTime, e.target.duration);
    },
    //再生位置を同期させる
    syncPosition: function() {
      var ratio = this.giveTime;
      console.log(ratio);
      var vi = document.getElementById("movie");
      var all = vi.duration;
      var location = all * ratio;
      vi.currentTime = location;
      return true;
    },
  },
};
</script>

<style lang="scss">
#mov {
  margin: 5px;
  video {
    width: 100%;
    height: 400px;
  }
}
</style>
