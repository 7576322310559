<template>
  <div id="apisnote">
    <panZoom selector="#zoom">
      <svg
        width="700"
        height="400"
        v-bind:viewBox="viewport"
        style="background: #eee"
      >
        <g id="zoom">
          <g v-for="(item, index) in apisnoteData" v-bind:key="item.id">
            <rect
              v-if="showOrNot(index)"
              v-bind:x="item.x"
              v-bind:y="item.y"
              width="130"
              v-bind:height="heightDecide(index)"
              v-bind:fill="ColorDecide(index)"
            ></rect>
            <switch>
              <foreignObject
                width="120"
                height="150"
                v-if="showOrNot(index)"
                v-bind:x="locationX(index)"
                v-bind:y="locationY(index)"
              >
                <p xmlns="http://www.w3.org/1999/xhtml">{{ item.note }}</p>
              </foreignObject>
            </switch>
            <!--
            <line
              v-if="linkManager(index, 0)"
              :x1="linkManager(index, 1)"
              :y1="linkManager(index, 2)"
              :x2="linkManager(index, 3)"
              :y2="linkManager(index, 4)"
              stroke="black"
            ></line>
            -->
          </g>
        </g>
      </svg>
    </panZoom>
    <v-slider v-bind:max="100" v-model="apisnoteRatio" step="1"> </v-slider>
    <v-row>
      <v-col>
        <img class="img" src="../../assets/apsinote_logo.png" />
      </v-col>
      <div v-for="(item, index) in notebook_json" :key="item.number">
        <v-col>
          <v-btn
            @click="whichShow(index)"
            v-on:mouseover="mouseOver(index)"
            v-on:mouseleave="mouseLeave"
            small
            v-bind:color="buttonManager(index)"
          >
            {{ item.notename }}
          </v-btn>
        </v-col>
      </div>
    </v-row>
    <div v-for="(item, index) in notebook_json" :key="item.number">
      <v-card>
        <p v-if="noteName(index)">{{ item.worksheet_name }}</p>
      </v-card>
    </div>
    <v-card v-if="currentWorksheetNameTF">
      {{ this.currentWorksheetName }}
    </v-card>
  </div>
</template>

<script>
import axios from "axios"; //axiosはhttp通信を簡単に行うことができるjsのライブラリ

export default {
  name: "Apisnote",
  props: {
    url: {
      type: String,
      required: true,
    },
    giveTime: {
      type: Date,
    },
    startTime: {
      type: Date,
    },
    endTime: {
      type: Date,
    },
  },
  data: () => ({
    apisnoteData: {},
    //show json
    width: 700,
    height: 400,
    max_json_items: 100,
    json_item_show: 100,
    notebook_index: 0,
    apisnoteRatio: 0,
    viewport: "0 0 700 400",
    time: 0,
    ratio: 0,
    x1_link: 0,
    x2_link: 0,
    y1_link: 200,
    y2_link: 200,
    overFlag: false,
    overButton: -1,
    currentWorksheetName: "ワークシート名",
    currentWorksheetNameTF: true,

    notebook_json: [
      {
        number: 1,
        color: "red lighten-4",
        notename: "W1",
        worksheet_guid: "2b18a56e-3ea1-4247-bd20-a36f228c02e7",
        worksheet_name: "A_1 構想の分析",
      },
      {
        number: 2,
        color: "blue lighten-4",
        notename: "W2",
        worksheet_guid: "1524a659-e472-4ce4-a255-1d5317fe6c81",
        worksheet_name: "A_2 企業分析、アイディア発想",
      },
      {
        number: 3,
        color: "yellow lighten-4",
        notename: "W3",
        worksheet_guid: "0f6e6455-4f99-40c9-a624-c4613ead7a98",
        worksheet_name: "A_3 評価マトリクス",
      },
      {
        number: 4,
        color: "orange lighten-4",
        notename: "W4",
        worksheet_guid: "d6b52fe3-7301-4ca0-a67a-ccad944aa559",
        worksheet_name: "A_4 アイデア精緻化",
      },
    ],
  }),
  methods: {
    mouseOver: function(index) {
      this.overFlag = true;
      this.overButton = index;
      this.currentWorksheetNameTF = false;
    },
    mouseLeave: function() {
      this.overFlag = false;
      this.overButton = -1;
      this.currentWorksheetNameTF = true;
    },
    noteName: function(index) {
      if (this.overButton == index) {
        if (this.overFlag) {
          return true;
        }
      }
      return false;
    },
    whichShow: function(index) {
      this.notebook_index = index;
    },
    buttonManager: function(index) {
      //この関数はボタンの色を変化させる
      return this.notebook_json[index].color;
    },
    viewPortChanger: function(a, b) {
      //"a b a+width b+height"にviewportを変化させる
      var tempview_str = this.viewport.split(" ");
      var tempview = tempview_str.map((str) => parseInt(str, 10));
      var tempwidth = tempview[2] - tempview[0];
      var tempheight = tempview[3] - tempview[1];
      tempview[0] = 0;
      tempview[1] = 0;
      tempview[2] = Number(a) + Number(tempwidth);
      tempview[3] = Number(b) + Number(tempheight);
      this.viewport =
        String(tempview[0]) +
        " " +
        String(tempview[1]) +
        " " +
        String(tempview[2]) +
        " " +
        String(tempview[3]);
      console.log(this.viewport, a, b, this.width, this.height);
    },
    ColorDecide(index) {
      //対応している色はwhite, blue , green ,yellow
      //配色はmatrial designのlighten-4を使用
      var color = this.apisnoteData[index].color;
      //console.log(color);
      if ((color == "light grey") | (color == "grey")) {
        return "#F5F5F5";
      } else if (color == "red" || color == "light red") {
        return "#FFCDD2";
      } else if (color == "green") {
        return "#C8E6C9";
      } else if (color == "blue" || color == "light blue") {
        return "#B3E5FC";
      } else if (color == "yellow") {
        return "#FFF9C4";
      } else if (color == "purple") {
        return "#D1C4E9";
      } else if (color == "orange") {
        return "#FFE0B2";
      } else if (color == "brown") {
        return "#D7CCC8";
      }
      return color;
    },
    heightDecide(index) {
      //この関数は付箋の高さを文字によって決める関数。
      var note = String(this.apisnoteData[index].note);
      var length = note.length;
      if (length < 20) {
        return 80;
      } else {
        if (length < 40) {
          return 160;
        } else {
          if (length < 60) {
            return 240;
          } else {
            return 320;
          }
        }
      }
    },
    locationX(index) {
      var x = Number(this.apisnoteData[index].x) + 10;
      return x;
    },
    locationY(index) {
      var y = Number(this.apisnoteData[index].y) + 10;
      return y;
    },
    ApisnoteTimeToDate: function(index) {
      //jsonファイルのindexを与えて一つのイベントを指定するとそのイベントの時刻をDate型で返す。
      //"2021 3 2 3 32 10"からDateへ変換する。
      var apisnoteDateString = this.apisnoteData[index].createdAt;
      //var apisnoteDateString = "2021 3 2 3 32 10";
      var str = apisnoteDateString.split(" ");
      var tempstring = str.map((str) => parseInt(str, 10));
      var year = tempstring[0];
      var month = tempstring[1];
      var day = tempstring[2];
      var hour = tempstring[3] + 9;
      var minute = tempstring[4];
      var second = tempstring[5];

      var date = new Date(year, month, day, hour, minute, second);
      //console.log(year, month, day, hour, minute, second);
      return date;
    },
    linkHistoryToDate: function(index) {
      var item = this.apisnoteData[index];
      //linkの時のみ実行
      var History = item.link_history.split(",");
      //console.log(History);
      var DateArray = new Array();

      for (let i = 0; i < History.length; i++) {
        var temp_history = String(History[i]);
        if (i != 0) {
          if (i == History.length - 1) {
            temp_history = temp_history.substring(1, temp_history.length);
          } else {
            temp_history = temp_history.substring(1, temp_history.length - 1);
          }
        }
        temp_history = temp_history.substring(1, temp_history.length - 1);
        temp_history = temp_history.split(" ");
        var tempstring = temp_history.map((temp_history) =>
          parseInt(temp_history)
        );
        var year = tempstring[0];
        var month = tempstring[1];
        var day = tempstring[2];
        var hour = tempstring[3] + 9;
        var minute = tempstring[4];
        var second = tempstring[5];
        if (isNaN(second)) {
          second = 0;
        }
        var date = new Date(year, month, day, hour, minute, second);
        DateArray.push(date);
        //console.log(tempstring, year, month, day, hour, minute, second);
      }
      return DateArray;
    },
    linkCoordinateManager: function(index, F, I) {
      var item = this.apisnoteData[index];
      //linkの時のみ実行
      var coordinate;
      if (F == 1) {
        coordinate = item.link_x1;
      }
      if (F == 2) {
        coordinate = item.link_y1;
      }
      if (F == 3) {
        coordinate = item.link_x2;
      }
      if (F == 4) {
        coordinate = item.link_y2;
      }
      var coordinate_array = coordinate.split(",");
      //console.log(coordinate_array, I);
      var str = coordinate_array[I];
      console.log("hoge", str);
      if (I == 0) {
        str = str.substring(1, str.length - 1);
        console.log(str);
        return Number(str);
      } else {
        str = str.substring(2, str.length - 1);
        console.log(str);
        return Number(str);
      }
    },
    linkManager: function(index, F) {
      var worksheet_guid = this.apisnoteData[index].worksheet_guid;
      var temp_worksheet_guid = this.notebook_json[this.notebook_index]
        .worksheet_guid;
      var item = this.apisnoteData[index];
      var type = item.type;
      if (worksheet_guid == temp_worksheet_guid) {
        if (type == "link") {
          var DateArray = this.linkHistoryToDate(index);
          var I = 0;
          //console.log(DateArray);
          for (let j = 0; j < DateArray.length; j++) {
            var tempdate = DateArray[j].getTime();
            var currentdate = this.giveTime.getTime();
            if (tempdate >= currentdate) {
              I = j - 1;
              break;
            }
          }
          if (I == -1) {
            return false;
          }
          if (F == 0) {
            return true;
          } else {
            return this.linkCoordinateManager(index, F, I);
          }
        } else {
          return false;
        }
      }
    },
    showOrNot: function(index) {
      this.max_json_items = Object.keys(this.apisnoteData).length;
      var apisnoteDate = this.ApisnoteTimeToDate(index);
      var currentDate = this.giveTime;
      var worksheet_guid = this.apisnoteData[index].worksheet_guid;
      var temp_worksheet_guid = this.notebook_json[this.notebook_index]
        .worksheet_guid;
      //console.log(currentDate, apisnoteDate);
      if (worksheet_guid == temp_worksheet_guid) {
        if (apisnoteDate < currentDate) {
          if (this.apisnoteData[index].x == "") {
            return false;
          } else {
            //時刻も範囲内であり、付箋のデータである場合
            if (index == this.max_json_items - 1) {
              //最後のデータなら必ず表示
              return true;
            } else {
              //もし一つ後の要素がguidが同じであり、かつ表示時刻範囲内に含まれるならば表示しない
              var guid = this.apisnoteData[index].guid;
              var guid_next = this.apisnoteData[index + 1].guid;
              var apisnoteDate_next = this.ApisnoteTimeToDate(index + 1);
              if (guid == guid_next) {
                //表示範囲内かどうか
                if (apisnoteDate_next < currentDate) {
                  return false;
                } else {
                  return true;
                }
              } else {
                return true;
              }
            }
          }
        } else {
          return false;
        }
      }
    },
  },
  watch: {
    url: function(value) {
      console.log("watched apiosnote url");
      console.log(this.url);
      axios.get(value).then((result) => {
        this.apisnoteData = result.data;
      });
      console.log("hoge", this.apisnoteData[0]);
    },
    giveTime: function() {
      //変更があったときにのみ呼び出されるようにしておく。
      var start = this.startTime.getTime();
      var end = this.endTime.getTime();
      var time = this.giveTime.getTime();
      this.apisnoteRatio = Number(((time - start) / (end - start)) * 100);
    },
    apisnoteRatio: function() {
      //スライドバーで更新した時にapisnoteの時刻のみ更新
      var start = this.startTime.getTime();
      var end = this.endTime.getTime();
      var ratio = this.apisnoteRatio / 100;
      var time = (end - start) * ratio + start;
      this.giveTime = new Date(time);
    },
  },
};
</script>
